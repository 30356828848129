export enum CompanyTypeOptions {
  VendorOrManufacturer = 'VendorOrManufacturer',
  Brokerage = 'Brokerage',
  RetailerOrReseller = 'RetailerOrReseller',
  Distributor = 'Distributor',
}

export const CompanyTypeAnswers = [
  {
    id: CompanyTypeOptions.VendorOrManufacturer,
    icon: 'assets/svg/signup-interview/vendor.svg',
    title: 'interviewPage.companySelection.answers.vendor.title',
    description: 'interviewPage.companySelection.answers.vendor.description',
  },
  {
    id: CompanyTypeOptions.Brokerage,
    icon: 'assets/svg/signup-interview/brokerage.svg',
    title: 'interviewPage.companySelection.answers.broker.title',
    description: 'interviewPage.companySelection.answers.broker.description',
  },
  {
    id: CompanyTypeOptions.RetailerOrReseller,
    icon: 'assets/svg/signup-interview/retailer.svg',
    title: 'interviewPage.companySelection.answers.retailer.title',
    description: 'interviewPage.companySelection.answers.retailer.description',
  },
  {
    id: CompanyTypeOptions.Distributor,
    icon: 'assets/svg/signup-interview/distributor.svg',
    title: 'interviewPage.companySelection.answers.distributor.title',
    description: 'interviewPage.companySelection.answers.distributor.description',
  },
];
